<template>
  <div>
    <div v-if="isAdmin()">
      <b-form-input
        v-model="searchText"
        class="search-text"
        type="text"
        debounce="150"
        placeholder="Search ID, Name, Login"
        autofocus
        autocomplete="off"
        @update="filterActivities"
      ></b-form-input>

      <b-table
        class="table-google table-activites mt-2"
        small
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="filteredActivities"
        :fields="fields">
          <template slot="cell(login)" slot-scope="data">
            <span :class="{ 'text-blocked font-weight-bold': data.item.blocked }">
                {{ data.value }}
            </span>
          </template>
          <template slot="cell(last_activity)" slot-scope="data">
            <span v-if="data.value>0">{{data.value | longdate }} {{data.value | time}}</span>
            <span v-else>Never</span>
          </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminActivities',
  computed: {
    IsLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      activities: [],
      filteredActivities: [],
      searchText: '',
      sortBy: 'last_activity',
      sortDesc: true,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'display_name', label: 'Name', sortable: true },
        { key: 'login', label: 'Login', sortable: true },
        { key: 'last_activity', label: 'Last activity', sortable: true },
      ],
      poller: null,
    };
  },
  methods: {
    filterActivities() {
      this.filteredActivities = this.activities.filter((account) => (
        account.id === this.searchText
          || account.login.toLowerCase().includes(this.searchText.toLowerCase())
          || account.display_name.toLowerCase().includes(this.searchText.toLowerCase())
      ));
    },
    fetchActivities() {
      this.loadingCount += 1;
      this.$http
        .get('/accounts_activities')
        .then((res) => {
          this.activities = res.body.activities;
          this.filterActivities();
        })
        .catch((err) => {
          alert(`Failed to fetch activities: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
  },
  mounted() {
    this.fetchActivities();
    this.poller = setInterval(this.fetchActivities, 60000);
  },
  beforeDestroy() {
    clearInterval(this.poller);
  },
};
</script>

<style lang="scss" scoped>
.search-text,
.table-activites {
  max-width: 800px;
}

.text-blocked {
  color: #db0000;
}
</style>
